<template>
  <div class="modal-heading small-heading">
    <div class="left-side">
      <h3>Popularity trend for <span class="blue">{{ currentKeywordItem }}</span></h3>
      <div class="country-select sub-select">
        <custom-select-search @add-app-search-handler="popularityCountryChanged"
                              @country-selected="popularityCountrySelectHandler"
                              no-active-border
                              :classes="['country-select-search no-shadow-results-block']"
                              :initial-data="{name: popularCountryCode?.name || currentCountryName, code: popularCountryCode?.code || currentCountryCode}"
                              :search-results="searchSapCountryResults">
          <template v-slot:header>Select country</template>
        </custom-select-search>
      </div>
    </div>
    <div class="right-side">
      <custom-dropdown :min-width="'112px'"
                       :data-updated="currentCountryCode">
        <template v-slot:header>
          <span class="capitalized">{{ popularSelectedPeriod }}</span>
        </template>
        <template v-slot:content>
          <div class="select-options" v-for="(PERIOD, key) in PERIODS" :key="key">
            <div class="option"
                 v-if="popularSelectedPeriod !== PERIOD"
                 @click="popularityPeriodChanged(PERIOD)">
              <span class="capitalized">{{ PERIOD }}</span>
            </div>
          </div>
        </template>
      </custom-dropdown>
    </div>
  </div>

  <div class="chart-block">
    <preloader-table-small v-if="showPopularChartLoader"
                           :loader-size="'40px'"></preloader-table-small>

    <template v-else>

      <vue-highcharts
          v-if="popularityChartResultsLength > 0"
          type="chart"
          :options="getPopularData()"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
          @updated="onUpdated"/>

      <div v-else>No Data</div>
    </template>
  </div>
</template>

<script>
import {formatDate} from "@/utils/utils";
import {keywordsPopularChartOptions} from "@/configs/keywords-popular-charts/keywords-popular-charts";
import {httpRequest} from "@/api";
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import CustomSelectWithSearch from "@/components/Forms/CustomSelectWithSearch/index.vue";
import {mapGetters} from "vuex";


export default {
  name: "SapChartModal",
  components: {
    'custom-dropdown': CustomDropdown,
    'custom-select-search': CustomSelectWithSearch,
  },
  props: {
    currentCountryName: {
      type: String,
    },
    currentCountryCode: {
      type: String,
    },
    currentKeywordItem: {}
  },
  data() {
    return {
      showTableChartModal: false,
      showPopularChartLoader: false,
      popularCountryCode: null,
      popularCountryName: null,
      searchSapCountryResults: {},
      PERIODS: {
        DAYS: 'days',
        WEEKS: 'weeks',
        MONTHS: 'months',
      },
      popularSelectedPeriod: null,
      SERIES_TYPES: {
        SOLID: 'solid',
        DASH: 'dash',
      },
    }
  },
  created() {
    this.popularSelectedPeriod = this.PERIODS.WEEKS;
  },
  async mounted() {
    this.popularCountryCode = this.currentCountryCode;
    this.popularCountryName = this.currentCountryName;
    await this.fetchPopularityChartResults();
  },
  methods: {
    getPopularData() {
      let popularityArray = [];

      for (const key in this.popularityChartResults) {
        popularityArray.push({
          date: formatDate(key, 'month-day-year'),
          rankData: this.popularityChartResults[key]
        });
      }

      let dates = [];
      let rank = [];

      for (const key in this.popularityChartResults) {
        dates.push(formatDate(key, 'month-day-year'));
        rank.push(this.popularityChartResults[key]);
      }

      const rankingData = popularityArray.sort(
          (a,b) =>  new Date(a.date).getTime() < new Date(b.date).getTime() ? -1 : 1
      );

      let self = this;
      const categories = rankingData.map(item => item.date);

      return {
        ...keywordsPopularChartOptions,
        chart: {
          width: 1000,
        },
        xAxis: {
          categories: categories,
          labels: {
            rotation: -45,
            style: {
              color: '#a2aabe',
              fontSize: '14px',
              textOverflow: 'none',
              whiteSpace: 'nowrap',
            },
            formatter: function () {
              return this.value;
            },
          }
        },
        yAxis: {
          title: false,
        },
        series: this.getSeries(rankingData, categories),
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                borderDash: [8, 4],
                color: '#f0f3fd',
                zeroLineColor: 'transparent',
                drawBorder: false,
                drawOnChartArea: true,
              },
              type: 'linear',
            }]
          },
        },
        tooltip: {
          enabled: true,
          width: 100,
          distance: 20,
          padding: 8,
          outside: false,
          useHTML: true,
          backgroundColor: '#fff',
          borderColor: '#d7dde7',
          borderRadius: 4,
          shadow: false,
          shape: 'square',
          hideDelay: 15,
          formatter: function () {
            let sapDifference = null;
            const category = this.point.category;

            for (const key in self.popularityChartResults) {
              if (!self.popularityChartResults[key].was_checked){
                continue;
              }

              if (category === formatDate(key, 'month-day-year')) {
                sapDifference = self.popularityChartResults[key]?.difference;
                break;
              }
            }

            const differenceColor = sapDifference > 0 ? 'green' : 'red';
            const differenceRenderValue = sapDifference > 0 ? '+' + sapDifference : sapDifference;
            let differenceBlock = '';

            if (sapDifference !== 0 && sapDifference !== null) {
              differenceBlock = `<span class="difference ${differenceColor}">${differenceRenderValue}</span>`;
            }

            return `<div class="tooltip-inner-container">${this.point.category}
                      <br />
                      <span class="rank">Popularity: <strong>${this.point.label ?? this.point.y}</strong>
                      ${differenceBlock}
                      </span>
                    </div>`
          }
        },
      };
    },
    getSeries(data, categories) {
      return this.popularSelectedPeriod === this.PERIODS.DAYS
          ? this.getDaysSeries(data, categories)
          : this.getDefaultSeries(data);
    },
    getDaysSeries(data, categories) {
      let seriesData, color, dashStyle, radius;
      let series = [];
      const splitData = this.splitDataIntoSeries(data);

      splitData.forEach((item, index) => {
        if (!item.was_checked && splitData.hasOwnProperty(index + 1)) {
          item.data.push(splitData[index + 1].data[0])
        }

        if (item.was_checked) {
          seriesData = item.data.map(item => ({
            x: categories.indexOf(item.date),
            y: item.rankData.SAP,
            label: item.rankData.SAP,
          }));
          color = '#7688ff';
          dashStyle = this.SERIES_TYPES.SOLID;
          radius = item.data.length === 1 ? 2 : 1;
        } else {
          seriesData = item.data.map(item => ({
            x: categories.indexOf(item.date),
            y: item.rankData.was_checked ? item.rankData.SAP : splitData[index - 1].data.slice(-1).pop().rankData.SAP,
            label: item.rankData.was_checked ? item.rankData.SAP : 'N/A',
          }));
          color = '#a2aabe';
          dashStyle = this.SERIES_TYPES.DASH;
          radius = 1;
        }

        series.push(this.createSeries(seriesData, color, dashStyle, radius))
      });

      return series;
    },
    createSeries(data, color, dashStyle, radius) {
      return {
        data: data,
        color: color,
        dashStyle: dashStyle,
        lineWidth: 2,
        marker: {
          symbol: 'circle',
          states: {
            fillColor: color,
            hover: {
              fillColor: color,
              radius: radius,
              lineWidth: 2,
              lineColor: color,
            }
          }
        }
      }
    },
    getDefaultSeries(data) {
      return [
          this.createSeries(
              data.map(item => item.rankData.SAP),
              '#7688ff',
              this.SERIES_TYPES.SOLID,
              2,
              '#FFFFFF',
          ),
      ];
    },
    splitDataIntoSeries(data) {
      const result = [];
      let currentSeries = [];
      let currentType = null;

      data.forEach((item, index) => {
        if (item.rankData.was_checked) {
          if (currentType !== this.SERIES_TYPES.SOLID) {
            if (currentSeries.length > 0) {
              result.push({data: currentSeries, was_checked: currentType === this.SERIES_TYPES.SOLID});
            }

            currentSeries = [];
            currentType = this.SERIES_TYPES.SOLID;
          }
          currentSeries.push({ index, ...item });
        } else {
          if (currentType !== this.SERIES_TYPES.DASH) {
            if (currentSeries.length > 0) {
              result.push({data: currentSeries, was_checked: currentType === this.SERIES_TYPES.SOLID});
            }

            currentSeries = [];
            currentType = this.SERIES_TYPES.DASH;
          }

          currentSeries.push({ index, ...item });
        }
      });


      if (currentSeries.length > 0) {
        result.push({data: currentSeries, was_checked: currentType === this.SERIES_TYPES.SOLID});
      }

      return result;
    },
    async fetchPopularityChartResults(period) {
      const store = this.currentApp?.store?.key ?? 'APP_STORE';
      this.showPopularChartLoader = true;

      if (period) {
        this.popularSelectedPeriod = period;
      }

      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.keywordsTracking.POPULARITY_DYNAMICS
          + '?keyword=' + encodeURIComponent(this.currentKeywordItem)
          + '&country_code=' + (this.popularCountryCode || this.currentCountryCode)
          + '&period=' + this.popularSelectedPeriod
          + '&store_key=' + store;

      const result = await httpRequest('GET', url);
      this.$store.dispatch('keywordsTracking/SET_POPULARITY_CHART_RESULTS', result?.popularity_dynamics);
      this.showPopularChartLoader = false;
      await this.popularityCountryChanged('');
    },
    async popularityCountryChanged(value) {
      if (value?.length >= 0) {
        const url = process.env.VUE_APP_API_URL
            + this.$ApiUrls.keywordsTracking.POPULARITY_DYNAMICS_COUNTRIES
            + '?country_query=' + value + '&keyword='
            + encodeURIComponent(this.currentKeywordItem);

        const result = await httpRequest('GET', url);

        if (result?.countries) {
          let countriesSapArray = [];
          for (const key in result.countries) {
            countriesSapArray.push({
              code: key,
              name: result.countries[key]?.name,
              sap: result.countries[key]?.SAP,
            });
          }
          this.searchSapCountryResults = {result: countriesSapArray};
        }
      } else {
        this.searchSapCountryResults = {};
      }
    },
    async popularityCountrySelectHandler(value) {
      this.popularCountryCode = value.code;
      this.popularCountryName = value.name;
      await this.fetchPopularityChartResults();
    },
    async popularityPeriodChanged(period) {
      this.popularSelectedPeriod = period;
      await this.fetchPopularityChartResults(period);
    },
    async fetchCountryCatalog() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_LOCALES;
      const result = await httpRequest('GET', url);
      this.$store.dispatch('SET_COUNTRY_LIST', result);
    },
  },
  computed: {
    ...mapGetters([
      'userData',
      'isMobile',
      'currentApp',
    ]),
    ...mapGetters('keywordsTracking', [
      'popularityChartResults',
    ]),
    popularityChartResultsLength() {
      return Object.values(this.popularityChartResults)?.length;
    },
  },
}
</script>
<style scoped>
/* vue-highcharts class automatically adds in <vue-highcharts/> components*/
.vue-highcharts{
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
</style>