<template>
  <div class="progress-wrap">
    <progress-bar :width="sap + '%'"
                  height="8px"
                  :fill-color="`background: ${calculateColor(sap)}`"
                  bar-type="custom"/>
  </div>
  <div class="value-block">{{ sap }}</div>
  <template v-if="storeKey && storeKey !== 'GOOGLE_PLAY'">

    <div v-if="storeKey && storeKey !== 'GOOGLE_PLAY'" class="chart-icon" @click="popularityChartClicked" v-tooltip="{ text: 'Open Chart', position: tooltipPos, classes: ['no-wrap-text']  }">
      <svg-icon icon="chart-icon" class="icons-margin chart-icon"/>
    </div>

    <custom-modal v-if="createSapChartModal"
                  :opened="showTableChartModal"
                  :max-width="728"
                  class-name="popularity-chart-modal"
                  @closeModal="closeModal('showTableChartModal')">
      <sap-chart-modal :current-country-code="countryCode"
                       :current-country-name="countryName"
                       :current-keyword-item="keyword"/>
    </custom-modal>
  </template>
</template>

<script>
import {defineComponent} from 'vue';
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import ProgressBar from "@/components/UI/ProgressBar/index.vue";
import {colorForProgressBar} from "@/utils/utils";

export default defineComponent({
  name: "SapTool",
  components: {
    SapChartModal,
    ProgressBar,
  },
  props: {
    keyword: {
      type: String,
    },
    sap: {
      type: Number,
    },
    storeKey: {
      type: String,
    },
    countryCode: {
      type: String,
    },
    countryName: {
      type: String,
    },
    tooltipPos: {
      type: String,
      default: 'bottom'
    }
  },
  emits: ['sap-modal-trigger-clicked'],
  data() {
    return {
      showTableChartModal: false,
    };
  },
  methods: {
    popularityChartClicked() {
      if (this.createSapChartModal) {
        this.openModal('showTableChartModal');
      }
      this.$emit('sap-modal-trigger-clicked', this.keyword);
    },
    calculateColor(count) {
      return colorForProgressBar(count);
    },
  },
  computed: {
    createSapChartModal() {
      return this.countryCode && this.countryName;
    }
  }
})
</script>

<style scoped lang="scss">
.chart-icon {
  font-size: 24px;
  color: #a3b0c5;
  margin-left: 2px;
  cursor: pointer;
  transition: color .3s;

  &:hover {
    .svg-icons {
      color: var(--primary-default);
    }
  }
}

.progress-wrap {
  width: 42px;
}

.value-block {
  white-space: nowrap;
  margin-left: 8px;
  min-width: 19px;
}
</style>