let width = 650;

if (window.innerWidth > 990 || (window.innerWidth >= 768 && window.innerWidth < 992)) {
    width = 650;
} else {
    width = 300;
}
export const keywordsPopularChartOptions = {
    chart: {
        type: 'area',
        width: width,
        height: 300,
        marginLeft: 65,
    },
    title: false,
    legend: {
        enabled: false
    },
    xAxis: {
        title: false,
        categories: [],
        gridLineColor: '#ffffff',
    },
    yAxis: {
        title: false,
        gridLineDashStyle: 'longdash',
    },
    plotOptions: {
        series: {
            color: '#7688ff',
            fillColor: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                    [0, '#e6edfb'],
                    [1, '#e6edfb']
                ]
            },
            marker: {
                fillColor: '#FFFFFF',
                lineWidth: 1,
                lineColor: null,
                radius: 3
            },
            states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    halo: {
                        size: 4,
                        attributes: {
                            fill: 'transparent',
                            'stroke-width': 2,
                            stroke: 'transparent'
                        }
                    },
                    lineWidth: 3,
                }
            },
            pointPlacement: 'on',
        }
    },
}